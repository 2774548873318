import React from 'react';
import styled from 'styled-components';

const StyledLabel = styled.label`
  cursor: pointer;
  text-indent: -9999px;
  width: 44px;
  height: 24px;
  background: ${({ checked }) => (checked ? '#22C759' : '#EAECF2')};
  display: block;
  border-radius: 100px;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    left: ${({ checked }) => (checked ? '55%' : '4px')};
    top: 3px;
    width: 18px;
    height: 18px;
    background: #fff;
    border-radius: 90px;
    transition: 0.3s;
  }
`;

const Switch = ({ toggled, onChange }) => {
  return (
    <StyledLabel htmlFor="checkbox" checked={toggled}>
      <input
        id="checkbox"
        type="checkbox"
        checked={toggled}
        onChange={onChange}
      />
    </StyledLabel>
  );
};

export default Switch;
