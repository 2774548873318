import React, { useState, useEffect, useContext } from 'react';
import hoist from 'hoist-non-react-statics';
import ProjectContext from '../../../../store/projectContext';
import { apiUrl } from '../../../../lib/config';
import { useAppClient } from '../../../../lib/AppProvider';
import { useQueryParams } from '../../../../lib/hooks';
import { useProjectSprint } from '../../../../components/SideBarFilter/withSprintFilter';
import { useProjectEpic } from '../../../../components/SideBarFilter/withEpicFilter';
import { useProjectTag } from '../../../../components/SideBarFilter/withTagFilter';

const COLUMN_OPTIONS = [
  { name: 'Ticket ID', value: 'ticket_id' },
  { name: 'Ticket Number', value: 'ticket_number' },
  { name: 'Ticket Name', value: 'ticket_name' },
  { name: 'Project ID', value: 'project_name' },
  { name: 'Members', value: 'assignee' },
  { name: 'Status', value: 'status' },
  { name: 'Description', value: 'description' },
  { name: 'Estimate Time', value: 'estimation_time' },
  { name: 'Total Spent Time', value: 'total_spent_time' },
  { name: 'Due Date', value: 'due_date' },
  { name: 'Sprints', value: 'sprints' },
  { name: 'Epic', value: 'epic' },
  { name: 'Tags', value: 'tags' },
];

export function withExportTicketsModal(Component) {
  function WithExportTicketsModal({ projectId, ...props }) {
    const client = useAppClient();
    const { projectState } = useContext(ProjectContext);
    const [queryParams] = useQueryParams();
    const { data: sprints } = useProjectSprint({ projectId });
    const { data: epics } = useProjectEpic({ projectId });
    const { data: tags } = useProjectTag({ projectId });
    const members = { data: projectState.members };
    const [exportPath, setExportPath] = useState('');
    const [selectedColumns, setSelectedColumns] = useState(
      COLUMN_OPTIONS.map(({ value }) => value)
    );

    const searchKeyword = queryParams.q;

    const selectedSprints =
      sprints?.filter(sprint =>
        (queryParams.sprintIds || '').split(',').includes(sprint.id)
      ) || [];

    const selectedEpics =
      epics?.filter(epic =>
        (queryParams.epicIds || '').split(',').includes(epic.id)
      ) || [];

    const selectedTags =
      tags?.filter(tag =>
        (queryParams.tagIds || '').split(',').includes(tag.id)
      ) || [];

    const selectedMembers =
      members.data?.filter(member =>
        (queryParams.memberIds || '').split(',').includes(member.id)
      ) || [];

    const isFiltering =
      searchKeyword ||
      selectedSprints.length ||
      selectedEpics.length ||
      selectedTags.length ||
      selectedMembers.length;

    useEffect(() => {
      let path = `${apiUrl}/digital_initiative/use_cases/${projectId}/stories/export.csv?param_token=${client.accessToken}`;

      selectedColumns.forEach(selectedColumn => {
        path += `&columns[]=${selectedColumn}`;
      });

      if (searchKeyword) {
        path += `&query=${searchKeyword}`;
      }

      selectedSprints.forEach(({ id }) => {
        path += `&sprints[]=${id}`;
      });

      selectedEpics.forEach(({ id }) => {
        path += `&epic[]=${id}`;
      });

      selectedTags.forEach(({ id }) => {
        path += `&tags[]=${id}`;
      });

      selectedMembers.forEach(({ id }) => {
        path += `&assignees[]=${id}`;
      });

      setExportPath(path);
    }, [
      projectId,
      client,
      searchKeyword,
      selectedColumns,
      selectedSprints,
      selectedEpics,
      selectedTags,
      selectedMembers,
    ]);

    function onCheckColumn(column) {
      if (selectedColumns.includes(column)) {
        setSelectedColumns(
          selectedColumns.filter(selectedColumn => selectedColumn !== column)
        );
      } else {
        setSelectedColumns([...selectedColumns, column]);
      }
    }

    const pageProps = {
      exportPath,
      exportable: selectedColumns.length > 0,
      column_options: COLUMN_OPTIONS,
      selectedColumns,
      isFiltering,
      searchKeyword,
      selectedSprints,
      selectedEpics,
      selectedTags,
      selectedMembers,
      onCheckColumn,
    };

    return <Component {...props} {...pageProps} />;
  }

  hoist(WithExportTicketsModal, Component);

  WithExportTicketsModal.displayName = `WithExportTicketsModal(${
    Component.displayName ?? Component.name ?? 'Component'
  })`;

  return WithExportTicketsModal;
}
