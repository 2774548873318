import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { FieldArray, Formik } from 'formik';
import { DateTime } from 'luxon';
import * as yup from 'yup';
import { useQuery } from 'react-query';
import { theme } from 'styled-tools';
import { Modal } from 'components/Modal';
import { EditableText } from 'features/settings/pages/SettingCompanyPage/EditableText';
import {
  Button,
  OutlineButton,
} from 'features/authentication/components/Button';
import { successToast } from 'lib/toast';
import { useAppClient } from 'lib/AppProvider';
import { Indicator } from 'components/SideBarFilter/Indicator';
import {
  TextField,
  TextFieldView,
} from 'features/authentication/components/TextField';
import { DatePicker } from 'components/DatePicker';
import { Checkbox } from 'components/Checkbox';
import { ClockIcon } from 'components/Icons';
import GlobalContext from 'store/globalContext';
import {
  useTicketAction,
  useTicketStandUpSearch,
} from 'features/projects/useTicketSearch';
import { SelectField } from 'components/Select';
import { LoadingIndicator } from 'components/LoadingIndicator';
import Switch from 'components/Switch';
import { DatePickerTime } from 'components/DatePickerTime';
import { ReactComponent as TicketEmpty } from '../../../../assets/images/Ticketempty.svg';
import { ConfirmStandUpModal } from './ConfirmStandUpModal';

const validationSchema = yup.object({
  slackChannelIds: yup
    .array()
    .required('Channels not selected. Please select channels for stand up.'),
});

const ChannelSelectItem = styled.div`
  display: grid;
  grid-template-columns: 24px 1fr;
  grid-gap: 10px;
  align-items: center;

  padding: 5px 0;

  cursor: pointer;
`;

const ChannelSelectBody = styled.div`
  min-width: 100%;

  > p {
    padding: 10px 0;
    > span {
      color: #9fa3ae;
    }
  }

  > .actions,
  > .field-group,
  > .list {
    margin-bottom: 15px;
  }

  > .list {
    overflow-y: auto;

    max-height: 100%;
  }

  > .empty {
    color: #999;
    text-align: center;
  }
  > .error {
    color: red;
  }
`;

export const ChannelSelect = styled(function ChannelSelect({
  isLoading,
  errors,
  touched,
  className,
  value,
  onChange,
  channels,
}) {
  const [mounted, setMounted] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [projectMembers, setProjectMembers] = useState([]);
  const [filteredMembers, setFilteredMembers] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]);

  useEffect(() => {
    if (!mounted && channels && channels.length > 0) {
      const memberList = channels?.map(item => ({
        ...item,
        isSelected: value?.some(v => v.id === item.id),
      }));
      setProjectMembers(memberList);
      setFilteredMembers(memberList);
      setMounted(true);
    }
  }, [channels, mounted, value]);

  useEffect(() => {
    if (searchQuery) {
      const memberList = projectMembers.filter(item =>
        item?.name?.toLowerCase()?.includes(searchQuery.toLowerCase())
      );
      setFilteredMembers(memberList);
    } else if (projectMembers.length > 0) {
      setFilteredMembers(projectMembers);
    }
  }, [searchQuery, projectMembers]);

  useEffect(() => {
    if (value.length > 0 && projectMembers.length > 0) {
      const filtered = projectMembers.filter(item =>
        value?.some(v => v.id === item.id)
      );
      setSelectedMembers(filtered);
    } else if (mounted) {
      setSelectedMembers([]);
    }
  }, [value, projectMembers, mounted]);

  return (
    <ChannelSelectBody className={className}>
      <h4>Send to Slack channels</h4>
      <p>
        <span>Number of Channels :</span> {value.length}
      </p>
      <div className="field-group">
        <TextFieldView
          placeholder="Search by channel name"
          value={searchQuery}
          onChange={e => setSearchQuery(e.target.value)}
        />
      </div>
      <div className="list">
        {isLoading ? (
          <LoadingIndicator />
        ) : channels && channels.length <= 0 ? (
          <div className="no-ticket">
            <div className="empty-title">No channel</div>
            <div className="empty-content">
              We did not find any slack channels. Please contact the development
              team to fix the issue of not finding slack channels.
            </div>
          </div>
        ) : (
          filteredMembers.map(item => {
            return (
              <ChannelSelectItem
                tabIndex={-1}
                key={item.id}
                onClick={() =>
                  selectedMembers?.some(selected => selected.id === item.id)
                    ? onChange(
                        value.filter(v => item.id !== v.id)?.map(v => v.id)
                      )
                    : onChange(value.concat(item)?.map(v => v.id))
                }
              >
                <Indicator
                  checked={selectedMembers?.some(
                    selected => selected.id === item.id
                  )}
                />
                <div className="name">{item.name}</div>
              </ChannelSelectItem>
            );
          })
        )}
      </div>
      {errors.slackChannelIds && touched.slackChannelIds && (
        <div className="error">{errors.slackChannelIds}</div>
      )}
    </ChannelSelectBody>
  );
})``;

export const StandUpModal = React.memo(
  styled(function StandUpModal({ className, isOpen, onClose, editTicket }) {
    const ticketArg = editTicket?.arg;
    const { globalState } = useContext(GlobalContext);
    const { profile } = globalState;
    const formRef = useRef();
    const client = useAppClient();
    const [isShowConfirmModal, setIsShowConfirmModal] = useState(false);
    const [previousDate, setPreviousDate] = useState(
      ticketArg?.previousDate
        ? DateTime.fromISO(ticketArg?.previousDate)
        : DateTime.utc().plus({ days: -1 })
    );
    const [laterDate, setLaterDate] = useState(
      ticketArg?.laterDate
        ? DateTime.fromISO(ticketArg?.laterDate)
        : DateTime.utc()
    );
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [form, setForm] = useState(null);

    const { createStandUp, updateStandUp } = useTicketAction();

    const { data: channels, isLoading: isLoadingChannel } = useQuery(
      ['slack-channels'],
      () => client.listChannels(),
      {
        enabled: !!isOpen,
        refetchOnWindowFocus: false,
      }
    );

    const statusOptions = [
      { label: 'In progress', value: 'In progress' },
      { label: 'Not started yet', value: 'Not started yet' },
      { label: 'Done', value: 'Done' },
    ];

    const {
      tickets: previousTickets,
      loading: previousLoading,
    } = useTicketStandUpSearch({
      cacheKey: 'previous-date-stand-up',
      dueDate: previousDate ? previousDate.toFormat('yyyy-MM-dd') : null,
      memberIds: [profile?.id],
      isOpen,
    });

    useEffect(() => {
      if (formRef.current && !previousLoading) {
        const editPreviousDateTickets = ticketArg?.previousDayStories;

        previousTickets.sort((a, b) => {
          const aTime =
            (a.startDate?.hour ?? 0) * 60 + (a.startDate?.minute ?? 0);
          const bTime =
            (b.startDate?.hour ?? 0) * 60 + (b.startDate?.minute ?? 0);
          return aTime - bTime;
        });

        formRef.current.setFieldValue(
          'previousDayStories',
          previousTickets.map(ticket => {
            const editTicketMatch = editPreviousDateTickets?.find(
              edit => edit.id === ticket.id
            );
            return {
              ...ticket,
              ticketStatus: editTicketMatch ? editTicketMatch.status : null,
              isSelected: !!editTicketMatch,
              timeRange:
                ticket.startDate && ticket.endDate
                  ? `${ticket.startDate.toFormat(
                      'hh:mma'
                    )} - ${ticket.endDate.toFormat('hh:mma')}`
                  : '',
            };
          })
        );
      }
    }, [formRef, previousTickets, previousLoading, ticketArg]);

    const {
      tickets: laterTickets,
      loading: laterLoading,
    } = useTicketStandUpSearch({
      cacheKey: 'later-date-stand-up',
      dueDate: laterDate ? laterDate.toFormat('yyyy-MM-dd') : null,
      memberIds: [profile?.id],
      isOpen,
    });

    useEffect(() => {
      if (formRef.current && !laterLoading) {
        const editLaterDateTickets = ticketArg?.laterDayStories;

        laterTickets.sort((a, b) => {
          const aTime =
            (a.startDate?.hour ?? 0) * 60 + (a.startDate?.minute ?? 0);
          const bTime =
            (b.startDate?.hour ?? 0) * 60 + (b.startDate?.minute ?? 0);
          return aTime - bTime;
        });

        formRef.current.setFieldValue(
          'laterDayStories',
          laterTickets.map(ticket => {
            const editTicketMatch = editLaterDateTickets?.find(
              edit => edit.id === ticket.id
            );
            return {
              ...ticket,
              ticketStatus: editTicketMatch ? editTicketMatch.status : null,
              isSelected: !!editTicketMatch,
              timeRange:
                ticket.startDate && ticket.endDate
                  ? `${ticket.startDate.toFormat(
                      'hh:mma'
                    )} - ${ticket.endDate.toFormat('hh:mma')}`
                  : '',
            };
          })
        );
      }
    }, [formRef, laterTickets, laterLoading, ticketArg]);

    const initialValues = useMemo(
      () => ({
        slackChannelIds: ticketArg?.slackChannelIds ?? [],
        previousDayStories: [],
        laterDayStories: [],
        blocker: ticketArg?.blocker ?? '',
        previousDate,
        laterDate,
        isScheduled: !!ticketArg?.isScheduled,
        scheduleTime: ticketArg?.scheduleTime
          ? DateTime.fromFormat(ticketArg?.scheduleTime, 'yyyy-MM-dd HH:mm', {
              zone: 'utc',
            })
          : DateTime.utc(),
      }),
      // eslint-disable-next-line
      []
    );

    async function handleSubmit(params) {
      setForm(params);
      onOpenConfirmModal();
    }

    function onOpenConfirmModal() {
      setIsShowConfirmModal(true);
    }

    function onCloseConfirmModal() {
      setIsShowConfirmModal(false);
    }

    async function onConfirmStandUp() {
      if (form) {
        setIsSubmitting(true);
        setIsShowConfirmModal(false);
        const selectedPreviousDayStories =
          form.previousDayStories?.filter(story => story.isSelected) ?? [];
        const selectedLaterDayStories =
          form.laterDayStories?.filter(story => story.isSelected) ?? [];

        if (editTicket) {
          await updateStandUp(editTicket.id, {
            blocker: form.blocker,
            previousDate: form.previousDate,
            laterDate: form.laterDate,
            isScheduled: form.isScheduled,
            scheduleTime: form.scheduleTime,
            slackChannelIds: form.slackChannelIds,
            previousDayStories: selectedPreviousDayStories,
            laterDayStories: selectedLaterDayStories,
          });
        } else {
          await createStandUp({
            blocker: form.blocker,
            previousDate: form.previousDate,
            laterDate: form.laterDate,
            isScheduled: form.isScheduled,
            scheduleTime: form.scheduleTime,
            slackChannelIds: form.slackChannelIds,
            previousDayStories: selectedPreviousDayStories,
            laterDayStories: selectedLaterDayStories,
          });
        }
        successToast({
          message: form.isScheduled
            ? 'Stand up has been scheduled.'
            : 'Stand up has been sent to Slack.',
        });
      }

      setPreviousDate(DateTime.utc().plus({ days: -1 }));
      setLaterDate(DateTime.utc());
      setIsSubmitting(false);
      setForm(null);
      onClose();
    }

    return (
      <>
        <Modal
          className="ticket-modal"
          isOpen={isOpen}
          onClose={() => {
            setPreviousDate(DateTime.utc().plus({ days: -1 }));
            setLaterDate(DateTime.utc());
            onClose();
          }}
          width="910px"
          padding="30px"
          hideCloseButton
        >
          <div className={className}>
            <Formik
              enableReinitialize
              validationSchema={validationSchema}
              initialValues={initialValues}
              innerRef={formRef}
              onSubmit={handleSubmit}
            >
              {({ values, setFieldValue, submitForm, errors, touched }) => {
                const selectedPreviousTickets = values.previousDayStories.filter(
                  story => story.isSelected
                );
                const sumPrevious = selectedPreviousTickets.reduce(
                  (sum, { point, pointMinute }) =>
                    sum + point * 60 + pointMinute,
                  0
                );
                const sumPreviousMinute = sumPrevious % 60;
                const sumPreviousHour = Math.trunc(sumPrevious / 60);

                const selectedLaterTickets = values.laterDayStories.filter(
                  story => story.isSelected
                );
                const sumLater = selectedLaterTickets.reduce(
                  (sum, { point, pointMinute }) =>
                    sum + point * 60 + pointMinute,
                  0
                );
                const sumLaterMinute = sumLater % 60;
                const sumLaterHour = Math.trunc(sumLater / 60);

                const FlagFields = () => (
                  <ChannelSelect
                    isLoading={isLoadingChannel}
                    touched={touched}
                    errors={errors}
                    channels={channels}
                    value={values.slackChannelIds.map(channel => ({
                      id: channel,
                    }))}
                    onChange={value => {
                      setFieldValue('slackChannelIds', value);
                    }}
                    isOpen={isOpen}
                  />
                );
                return (
                  <div className="form">
                    <div className="header is-mobile">
                      <div className="detail">Stand up</div>
                      <div>
                        <div className="actions is-hidden-mobile">
                          <OutlineButton
                            className="button cancel"
                            type="button"
                            onClick={() => {
                              setPreviousDate(
                                DateTime.utc().plus({ days: -1 })
                              );
                              setLaterDate(DateTime.utc());
                              onClose();
                            }}
                          >
                            Cancel
                          </OutlineButton>
                          <Button type="submit" onClick={submitForm}>
                            Confirm
                          </Button>
                        </div>
                      </div>
                    </div>
                    <div className="sprint-group is-mobile ">
                      <div className="select-group flag-fields is-hidden-desktop">
                        <FlagFields />
                      </div>
                    </div>

                    <div className="detail">
                      <div className="group">
                        <div className="detail-group">
                          <div className="header">
                            <div className="date-select">
                              <h4>What did you do on</h4>
                              <DatePicker
                                value={values.previousDate}
                                onChange={previousDay => {
                                  setFieldValue('previousDate', previousDay);
                                  setPreviousDate(previousDay);
                                }}
                                size="sm"
                                hiddenArrowIcon
                                showDateIcon
                                name="previousDate"
                              />
                            </div>
                            <div className="total-time">
                              <span>Total time spent :</span> {sumPreviousHour}h{' '}
                              {sumPreviousMinute}m
                            </div>
                          </div>

                          {previousLoading ? (
                            <div className="loading">
                              <LoadingIndicator />
                            </div>
                          ) : previousTickets.length <= 0 ? (
                            <div className="no-ticket">
                              <TicketEmpty />
                              <div className="empty-title">No ticket</div>
                              <div className="empty-content">
                                There are no tickets available today or tickets
                                may not have a due date.
                                <br />
                                Before the stand up, please arrange your
                                tickets.
                              </div>
                            </div>
                          ) : (
                            <div className="ticket-list">
                              <FieldArray
                                name="previousDayStories"
                                render={({ replace }) => {
                                  return values.previousDayStories.map(
                                    (story, i) => (
                                      <div key={i} className="ticket">
                                        <Checkbox
                                          checked={story.isSelected}
                                          onChange={e => {
                                            const check = e.target.checked;
                                            replace(i, {
                                              ...story,
                                              isSelected: check,
                                              ticketStatus: check
                                                ? 'Done'
                                                : null,
                                            });
                                          }}
                                        />
                                        <div
                                          className="title"
                                          onClick={() => {
                                            replace(i, {
                                              ...story,
                                              isSelected: !story.isSelected,
                                              ticketStatus: !story.isSelected
                                                ? 'Done'
                                                : null,
                                            });
                                          }}
                                        >
                                          <div className="name">
                                            {story.name}
                                          </div>
                                          <div className="estimation">
                                            <ClockIcon />
                                            <span>
                                              {story.timeRange
                                                ? story.timeRange
                                                : '-'}{' '}
                                              | {story.point ?? '0'}h{' '}
                                              {story.pointMinute ?? '0'}m
                                            </span>
                                          </div>
                                        </div>
                                        <SelectField
                                          noShadow
                                          placeholder="Not selected"
                                          className="status-select"
                                          name={`previousDayStories[${i}].ticketStatus`}
                                          options={statusOptions}
                                        />
                                      </div>
                                    )
                                  );
                                }}
                              />
                            </div>
                          )}

                          <div className="header">
                            <div className="date-select">
                              <h4>What did you do on</h4>
                              <DatePicker
                                value={values.laterDate}
                                onChange={laterDay => {
                                  setFieldValue('laterDate', laterDay);
                                  setLaterDate(laterDay);
                                }}
                                size="sm"
                                hiddenArrowIcon
                                showDateIcon
                                name="laterDate"
                              />
                            </div>
                            <div className="total-time">
                              <span>Total time spent :</span> {sumLaterHour}h{' '}
                              {sumLaterMinute}m
                            </div>
                          </div>
                          {laterLoading ? (
                            <div className="loading">
                              <LoadingIndicator />
                            </div>
                          ) : laterTickets.length <= 0 ? (
                            <div className="no-ticket">
                              <TicketEmpty />
                              <div className="empty-title">No ticket</div>
                              <div className="empty-content">
                                There are no tickets available today or tickets
                                may not have a due date.
                                <br />
                                Before the stand up, please arrange your
                                tickets.
                              </div>
                            </div>
                          ) : (
                            <div className="ticket-list">
                              <FieldArray
                                name="laterDayStories"
                                render={({ replace }) => {
                                  return values.laterDayStories.map(
                                    (story, i) => (
                                      <div key={i} className="ticket">
                                        <Checkbox
                                          checked={story.isSelected}
                                          onChange={e => {
                                            const check = e.target.checked;
                                            replace(i, {
                                              ...story,
                                              isSelected: check,
                                              ticketStatus: check
                                                ? 'In progress'
                                                : null,
                                            });
                                          }}
                                        />
                                        <div
                                          className="title"
                                          onClick={() => {
                                            replace(i, {
                                              ...story,
                                              isSelected: !story.isSelected,
                                              ticketStatus: !story.isSelected
                                                ? 'In progress'
                                                : null,
                                            });
                                          }}
                                        >
                                          <div className="name">
                                            {story.name}
                                          </div>
                                          <div className="estimation">
                                            <ClockIcon />
                                            <span>
                                              {story.timeRange
                                                ? story.timeRange
                                                : '-'}{' '}
                                              | {story.point ?? '0'}h{' '}
                                              {story.pointMinute ?? '0'}m
                                            </span>
                                          </div>
                                        </div>
                                        <SelectField
                                          noShadow
                                          placeholder="Not selected"
                                          className="status-select"
                                          name={`laterDayStories[${i}].ticketStatus`}
                                          options={statusOptions}
                                        />
                                      </div>
                                    )
                                  );
                                }}
                              />
                            </div>
                          )}

                          <h4>Blocker</h4>
                          <TextField
                            name="blocker"
                            placeholder="What are you blocked on? (Optional)"
                            multiline
                            rows={6}
                            oldLabel
                          />
                          <div className="schedule-section">
                            <div className="label">
                              <h4>Scheduling</h4>
                              <p>
                                Schedule your ticket for the desired times, or
                                manually select a date and time in the future to
                                stand up your ticket.
                              </p>
                            </div>
                            <Switch
                              className="switch"
                              id="switch"
                              toggled={values.isScheduled}
                              onChange={e =>
                                setFieldValue('isScheduled', e.target.checked)
                              }
                            />
                          </div>
                          {values.isScheduled && (
                            <div className="section row-date">
                              <div>
                                <DatePicker
                                  label="Stand up date"
                                  value={values.scheduleTime}
                                  onChange={dueDate => {
                                    setFieldValue('scheduleTime', dueDate);
                                  }}
                                  size="sm"
                                  hiddenArrowIcon
                                  showDateIcon
                                  name="dueDate"
                                />
                              </div>
                              <div>
                                <DatePickerTime
                                  label="Stand up time"
                                  value={values.scheduleTime}
                                  onChange={startDate => {
                                    setFieldValue('scheduleTime', startDate);
                                  }}
                                  size="sm"
                                />
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="section is-hidden-desktop">
                          <div className="actions">
                            <OutlineButton
                              className="button cancel"
                              type="button"
                              onClick={() => {
                                setPreviousDate(
                                  DateTime.utc().plus({ days: -1 })
                                );
                                setLaterDate(DateTime.utc());
                                onClose();
                              }}
                            >
                              Cancel
                            </OutlineButton>
                            <Button type="submit" onClick={submitForm}>
                              Confirm
                            </Button>
                          </div>
                        </div>
                      </div>
                      <div className="sidebar">
                        <div className="select-group flag-fields">
                          <FlagFields />
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }}
            </Formik>
          </div>
        </Modal>
        <ConfirmStandUpModal
          isOpen={isShowConfirmModal}
          isScheduled={form?.isScheduled}
          isSubmitting={isSubmitting}
          onClose={onCloseConfirmModal}
          onConfirm={onConfirmStandUp}
        />
      </>
    );
  })`
    position: relative;

    .schedule-section {
      display: grid;
      grid-template-columns: 1fr 30px;
      align-items: center;

      p {
        margin-top: 5px;
        color: #9fa3ae;
      }
    }

    .row-date {
      margin-top: 10px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 10px;
    }

    .empty-title {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 10px;
      color: #9fa3ae;
    }

    .no-ticket {
      height: 300px;
      text-align: center;
      padding: 60px 0;
    }
    .empty-content {
      font-size: 14px;
      color: #9fa3ae;
    }

    .is-hidden-desktop {
      display: none;
    }

    .loading {
      background: unset;

      &:after {
        margin: unset;
      }
    }

    @media (max-width: ${theme('breakpoints.mobile')}px) {
      .is-hidden-mobile {
        display: none !important;
      }

      .is-hidden-desktop {
        display: unset !important;
      }

      .repeat-group .row-date {
        margin-bottom: 10px;
      }

      .section.is-hidden-desktop {
        > .actions {
          background: white;
          justify-content: space-between;
          padding: 0 15px;
          padding-top: 30px;

          button {
            width: 100%;
          }
        }

        > .activity-form {
          grid-template-columns: 100%;
          grid-gap: unset;
          margin-top: 10px;
          margin-bottom: 10px;
          padding: 0 20px;
          background: white;

          .form {
            background: white;
          }

          .activity-log {
            align-items: start;

            .right-section .header {
              flex-wrap: wrap;
              line-height: 1.5;
            }

            .see-change {
              font-size: 14px;
            }
          }
        }
      }
    }

    .react-datepicker-popper {
      z-index: 2;
    }

    .section.recurrence {
      width: 280px;
    }

    .form {
      @media (max-width: ${theme('breakpoints.mobile')}px) {
        .action-group {
          display: flex;
          justify-content: space-between;
        }

        > .detail {
          padding: unset !important;
        }
      }

      > .detail > .sidebar {
        > .select-group {
          margin-bottom: 10px;
        }

        > .btn {
          border-color: #c6d5d5;
          width: 100%;

          color: #333;
          font-size: 14px;
          font-weight: bold;

          box-shadow: none;
          background: #f5f8f8;

          margin-bottom: 10px;
        }

        @media (max-width: ${theme('breakpoints.mobile')}px) {
          grid-row: 1;

          > .flag-fields {
            display: none;
          }
        }
      }

      @media (max-width: ${theme('breakpoints.mobile')}px) {
        .is-mobile {
          padding: 15px;
          margin-bottom: 10px !important;
          background: white;
        }

        background: #f5f8f8;
      }

      > .header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        border-bottom: 2px solid #f5f5f5;
        padding-bottom: 20px;

        @media (max-width: ${theme('breakpoints.mobile')}px) {
          flex-direction: column-reverse;
        }
      }

      > .header > .detail {
        margin-right: 20px;
        font-size: 20px;
        font-weight: bold;
      }

      > .header ${EditableText} {
        flex: 1;
        > .text {
          font-size: 20px;

          > .error {
            font-size: 12px;
          }

          &.error {
            color: ${theme('colors.error')};
          }
        }
      }

      .project-name {
        font-size: 12px;

        @media (max-width: ${theme('breakpoints.mobile')}px) {
          font-size: 14px;
        }
      }

      > .header .project-name > span {
        color: ${theme('colors.primary')};
      }

      > .header ${Button} {
        position: relative;
        top: -5px;

        @media (max-width: ${theme('breakpoints.mobile')}px) {
          float: right;
        }
      }

      > .section.member-list {
        margin-top: 16px;

        @media (max-width: ${theme('breakpoints.mobile')}px) {
          margin-top: unset;

          .member-item button div {
            padding-left: 0;
          }
        }

        > .title {
          display: block;

          margin-bottom: 8px;

          color: #c1c3ca;
          font-size: 12px;
          font-weight: normal;
        }
      }

      > .detail {
        > div > .select-group {
          border-radius: 6px;
          padding: 20px;
          background: #f5f8f8;
        }

        > .group {
          width: 100%;

          > .detail-group {
            > .loading {
              height: 300px;
            }
            > .header {
              display: flex;
              gap: 10px;
              align-items: center;
              justify-content: space-between;

              > .date-select {
                display: flex;
                gap: 10px;
                align-items: center;
              }

              > .total-time {
                > span {
                  color: #9fa3ae;
                }
              }
            }
            > .ticket-list {
              margin: 10px 0;
            }
            > h4 {
              margin-bottom: 10px;
            }
          }

          @media (max-width: ${theme('breakpoints.mobile')}px) {
            .repeat-group {
              background: white;
              padding-left: 15px;
              padding-right: 15px;
            }
          }

          > .section {
            margin-bottom: 20px;

            @media (max-width: ${theme('breakpoints.mobile')}px) {
              margin-bottom: unset;
            }

            > .title {
              display: block;

              margin-bottom: 8px;

              color: #c1c3ca;
              font-size: 12px;
              font-weight: normal;
            }

            .field.selected {
              padding-right: 32px;

              > div {
                overflow: hidden;
              }

              @media (max-width: ${theme('breakpoints.mobile')}px) {
                min-height: 40px;
                font-size: 14px;
              }
            }
          }

          .custom-repeat {
            display: flex;
            flex-direction: column;
            row-gap: 20px;
            font-size: 12px;
            color: #c1c3ca;

            > .row {
              display: flex;
              justify-content: space-between;
              column-gap: 12px;
            }

            > .row .week {
              width: 100%;
            }

            span {
              color: #3a3a3a;
            }

            .card-list {
              display: grid;
              grid-template-columns: repeat(3, 1fr);
              grid-gap: 10px;
              margin-top: 16px;
              font-size: 14px;

              .card {
                padding-top: 12px;
                padding-bottom: 12px;
                border-radius: 6px;
                border: solid 1px #dce0ea;
                text-align: center;
                color: #3a3a3a;
                cursor: pointer;
              }

              .card.active {
                border: solid 1px #22c759;
                color: #22c759;
              }
            }

            .column-list {
              display: grid;
              grid-template-columns: 1fr;
              row-gap: 20px;
              margin-top: 16px;

              .date-picker {
                width: 210px;
              }

              .custom-group {
                display: flex;
                align-items: center;
                column-gap: 20px;

                .label {
                  width: 30px;
                }

                .occurrence {
                  width: 210px;
                  margin-bottom: unset;
                }
              }
            }
          }

          > .row-date {
            display: grid;
            grid-template-columns: 280px repeat(2, 1fr);
            grid-gap: 10px;

            .date-type {
              margin-top: 20px;
            }

            > .group {
              position: relative;
              width: 100%;
              display: flex;
              align-items: center;

              .date-picker {
                width: 100%;
                position: absolute;
              }

              > div {
                position: absolute;
                right: 0;
              }

              .icon-close {
                cursor: pointer;
                background-color: ${theme('colors.primary')};
                color: #fff;
                border-radius: 50%;
                height: 20px;
                width: 20px;
                padding: 6px;
                font-size: 12px;
                line-height: 1;
                text-align: center;
                display: table-cell;
                vertical-align: middle;
                margin-top: 18px;
                margin-right: 2rem;

                &:hover {
                  ::after {
                    background: black;
                  }
                }
              }

              @media (max-width: ${theme('breakpoints.tablet')}px) {
                margin-top: 10px;
                margin-bottom: 20px;
              }
            }

            @media (max-width: ${theme('breakpoints.tablet')}px) {
              grid-template-columns: 100%;
              grid-gap: 10px;
            }

            @media (max-width: ${theme('breakpoints.mobile')}px) {
              background: white;
              padding: 15px;
              padding-top: 0;
              grid-template-columns: repeat(2, 1fr) !important;
              grid-gap: 10px !important;

              div:first-child {
                grid-column: span 2;
              }

              > .flag-fields {
                display: block;
              }
            }
          }

          > .row {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 10px;

            @media (max-width: ${theme('breakpoints.mobile')}px) {
              background: white;
              padding: 15px;
              padding-bottom: 0;
              grid-template-columns: repeat(2, 1fr) !important;
              grid-gap: 10px !important;

              div:first-child {
                grid-column: span 2;
              }
            }

            > .flag-fields {
              display: none;
              margin-top: 16px;
            }

            @media (max-width: ${theme('breakpoints.tablet')}px) {
              grid-template-columns: 100%;
              grid-gap: unset;
            }

            @media (max-width: ${theme('breakpoints.mobile')}px) {
              > .flag-fields {
                display: block;
              }
            }
          }
        }
      }

      > .on-drag-enter {
        position: absolute;
        top: -30px;
        left: -30px;
        width: calc(100% + 60px);
        height: calc(100% + 60px);
        border-radius: 6px;
        z-index: -1;
        background: white;
        padding: 30px;

        > .area-drop {
          border: 10px dashed ${theme('colors.primary')};
          height: 100%;
          border-radius: 6px;
          font-size: 36px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: bold;
          color: ${theme('colors.primary')};
        }

        &.dragging {
          z-index: 1;
        }
      }
    }

    .form > .detail,
    > .activity-form {
      display: grid;
      grid-template-columns: minmax(0, 1fr) minmax(0, 250px);
      grid-gap: 30px;
      padding: 20px 0;

      @media (max-width: ${theme('breakpoints.mobile')}px) {
        grid-template-columns: 100%;
        grid-gap: unset;
        margin-top: 10px;
        padding: 0 20px;

        .form {
          background: white;
        }

        .activity-log {
          align-items: start;

          .right-section .header {
            flex-wrap: wrap;
            line-height: 1.5;
          }

          .see-change {
            font-size: 14px;
          }
        }
      }
    }

    .ticket-list {
      margin-bottom: 20px;

      > .ticket {
        margin: 10px 0;
        display: grid;
        grid-template-columns: 30px 1fr 140px;
        align-items: center;

        border-radius: 6px;
        padding: 10px 16px;

        background: #f5f8f8;

        > .title {
          font-size: 14px;
          cursor: pointer;
          width: 350px;

          > .name {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          > .estimation {
            padding-right: 10px;
            white-space: pre;

            color: ${theme('colors.grey')};
            font-size: 14px;
            line-height: 32px;

            svg {
              margin-right: 5px;
            }
          }
        }

        > .status-select {
          margin: 0;

          > .input-group > .field {
            background: #f5f8f8;
            border: none;
          }
        }

        > .assignees {
          justify-content: flex-end;
        }

        > .menu {
          color: #999;
        }
      }

      > .subtask {
        margin-bottom: 10px;

        @media (max-width: ${theme('breakpoints.mobile')}px) {
          grid-template-areas: 'title . menu' 'estimation assignees .';

          grid-template-columns: repeat(2, 1fr) 30px;
          padding: 10px;

          .field-group {
            grid-template-columns: repeat(2, 1fr);

            .text-field:first-child {
              grid-column: span 2;
            }

            .actions-mobile {
              display: flex;
              justify-content: end;
            }

            .member {
              border-left: unset;

              button {
                margin-left: unset;
              }

              .avatar-block {
                margin-right: unset;
                margin-left: -10px;
              }

              .placeholder {
                margin-left: -10px;
              }
            }
          }

          .title {
            grid-area: title;
            grid-column: span 2;

            span:first-child {
              margin-left: unset;
            }
          }

          .estimation {
            grid-area: estimation;

            svg {
              color: #9fa3ae;
            }
          }

          .assignees {
            grid-area: assignees;
            justify-content: flex-start;
            padding-left: 20px;

            .avatar-block {
              margin-right: unset;
              margin-left: -10px;
            }
          }

          .menu {
            grid-area: menu;
          }
        }
      }
    }

    .subtask-btn {
      width: 100%;
      font-size: 14px;
      font-weight: normal;

      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 10px;

      > div {
        background: #22c759;
        border-radius: 50%;
        text-align: center;

        svg {
          color: white;
          width: 12px;
          height: 12px;
          margin: 2px;
        }
      }
    }

    .wrapper-ticket-action {
      display: flex;
      justify-content: end;
      margin-top: 15px;

      .btn-action {
        width: 32px;
        height: 32px;
        margin: 0 0 0 10px;
        padding: 6px;
        border-radius: 6px;
        border: solid 1px #c6d5d5;
        background-color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .actions {
      grid-area: actions;
      display: flex;
      justify-content: flex-end;
      align-self: center;

      .cancel {
        border: solid 1px #c2c3ca;
        color: #c2c3ca;
      }

      .cancel:hover {
        background-color: #c2c3ca;
        color: white;
      }

      > ${Button} {
        &.delete {
          color: #c1c3ca;
          border: none;
        }

        &.delete:hover {
          background: ${theme('colors.error')};
          color: white;
        }

        &:not(:last-child) {
          margin-right: 8px;
        }

        &:disabled {
          background: #adadad;
          cursor: not-allowed;
          border: none;
        }
      }
    }
  `
);
