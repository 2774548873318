import React, { useState } from 'react';
import styled from 'styled-components';
import { DateTime } from 'luxon';
import { EllipseVerticalIcon } from 'components/Icons';
import { IconButton } from 'features/authentication/components/Button';
import Tooltip from '@reach/tooltip';

const ScheduleListItemHeader = styled.div`
  display: grid;
  grid-template-columns: 200px 200px 1fr 150px 50px;
  padding: 20px;
  gap: 20px;
  background-color: #fff;
  color: #767676;
  font-size: 12px;

  > .col {
    display: flex;
    align-items: center;
    color: #9fa3ae;

    &.date {
      cursor: pointer;
      > svg {
        margin-left: 5px;
      }
    }

    &.schedule {
      justify-content: end;
    }
  }

  > .summary {
    padding-left: 0;
  }
`;

const ScheduleListItem = styled(
  React.forwardRef(function ScheduleListItem(
    {
      schedule,
      channels,
      color,
      onStandUpNow,
      onDeleteSchedule,
      onEditSchedule,
      setSelectScheduleId,
      lastItem,
      isOpenMenu,
      onCloseMenu,
      ...props
    },
    ref
  ) {
    const channelMap = channels.filter(channel =>
      schedule.arg.slackChannelIds.includes(channel.id)
    );
    const sumPrevious = (schedule.arg.previousDayStories ?? []).reduce(
      (sum, { estimationTime, estimationTimeMinute }) =>
        sum +
        parseInt(estimationTime, 10) * 60 +
        parseInt(estimationTimeMinute, 10),
      0
    );
    const sumPreviousMinute = sumPrevious % 60;
    const sumPreviousHour = Math.trunc(sumPrevious / 60);

    const sumLater = (schedule.arg.laterDayStories ?? []).reduce(
      (sum, { estimationTime, estimationTimeMinute }) =>
        sum +
        parseInt(estimationTime, 10) * 60 +
        parseInt(estimationTimeMinute, 10),
      0
    );

    const sumLaterMinute = sumLater % 60;
    const sumLaterHour = Math.trunc(sumLater / 60);

    return (
      <>
        <div {...props} ref={ref}>
          <div className="col account">
            {DateTime.fromISO(schedule.arg.previousDate).toFormat('d MMM yyyy')}
            {schedule.arg.previousDayStories &&
            schedule.arg.previousDayStories.length > 0 ? (
              <Tooltip
                label={
                  <>
                    <h2 style={{ padding: '5px 0' }}>
                      <span style={{ color: '#9FA3AE' }}>
                        Total time spent :
                      </span>
                      {sumPreviousHour}h {sumPreviousMinute}m
                    </h2>
                    {(schedule.arg.previousDayStories ?? []).map((story, i) => (
                      <p
                        key={i}
                        style={{
                          padding: '5px 0',
                          width: '380px',
                          whiteSpace: 'initial',
                        }}
                      >
                        <span style={{ color: '#9FA3AE' }}>
                          {story.timeRange ?? ''}
                        </span>{' '}
                        {story.name} -{' '}
                        <span style={{ color: '#22C759', fontWeight: 'bold' }}>
                          {story.status}
                        </span>
                      </p>
                    ))}
                  </>
                }
                style={{
                  background: 'black',
                  color: 'white',
                  border: 'none',
                  borderRadius: '3px',
                  padding: '0.5em 1em',
                  width: '400px',
                  zIndex: 10000,
                }}
              >
                <span className="have-ticket">
                  {' '}
                  {schedule.arg.previousDayStories.length} tickets
                </span>
              </Tooltip>
            ) : (
              <span> no ticket </span>
            )}
          </div>
          <div className="col position">
            {DateTime.fromISO(schedule.arg.laterDate).toFormat('d MMM yyyy')}
            {schedule.arg.laterDayStories &&
            schedule.arg.laterDayStories.length > 0 ? (
              <Tooltip
                label={
                  <>
                    <h2 style={{ padding: '5px 0' }}>
                      <span style={{ color: '#9FA3AE' }}>
                        Total time spent :
                      </span>
                      {sumLaterHour}h {sumLaterMinute}m
                    </h2>
                    {(schedule.arg.laterDayStories ?? []).map((story, i) => (
                      <p
                        key={i}
                        style={{
                          padding: '5px 0',
                          width: '380px',
                          whiteSpace: 'initial',
                        }}
                      >
                        <span style={{ color: '#9FA3AE' }}>
                          {story.timeRange ?? ''}
                        </span>{' '}
                        {story.name} -{' '}
                        <span style={{ color: '#22C759', fontWeight: 'bold' }}>
                          {story.status}
                        </span>
                      </p>
                    ))}
                  </>
                }
                style={{
                  background: 'black',
                  color: 'white',
                  border: 'none',
                  borderRadius: '3px',
                  padding: '0.5em 1em',
                  width: '400px',
                  zIndex: 10000,
                }}
              >
                <span className="have-ticket">
                  {' '}
                  {schedule.arg.laterDayStories.length} tickets
                </span>
              </Tooltip>
            ) : (
              <span> no ticket </span>
            )}
          </div>
          <Tooltip
            label={
              <>
                {(channelMap ?? []).map((channel, i) => (
                  <p key={i}>{channel.name}</p>
                ))}
              </>
            }
            style={{
              background: 'black',
              color: 'white',
              border: 'none',
              borderRadius: '3px',
              padding: '0.5em 1em',
              zIndex: 10000,
            }}
          >
            <div className="col level">{channelMap.length} channels</div>
          </Tooltip>

          <div className="col date">
            {DateTime.fromISO(schedule.at).toFormat('d MMM yyyy, HH:mm')}
          </div>
          <div className="col menu">
            <IconButton
              className="menu-icon"
              onClick={() => setSelectScheduleId(schedule.id)}
            >
              <EllipseVerticalIcon color={isOpenMenu ? color : null} />
            </IconButton>
            {isOpenMenu && (
              <div className={`schedule-menu ${lastItem ? 'last-item' : ''}`}>
                <div
                  onClick={() => {
                    setSelectScheduleId();
                    onEditSchedule(schedule.id);
                  }}
                >
                  <span>Edit</span>
                </div>
                <div
                  className="active"
                  onClick={() => {
                    setSelectScheduleId();
                    onStandUpNow(schedule.id);
                  }}
                >
                  <span>Stand up now</span>
                </div>
                <div
                  onClick={() => {
                    setSelectScheduleId();
                    onDeleteSchedule(schedule.id);
                  }}
                >
                  <span>Delete</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    );
  })
)`
  display: grid;
  grid-template-columns: 200px 200px 1fr 150px 50px;
  padding: 15px 20px;
  gap: 20px;

  color: #999;
  font-size: 12px;
  font-weight: bold;

  background: #f5f8f8;

  [data-reach-tooltip] {
    h2 {
      color: green;
    }
  }

  &.odd-row {
    background: white;
    font-size: 14px;
  }

  &.even {
    font-size: 14px;
  }

  > .col {
    color: #3a3a3a;
    display: flex;
    align-items: center;
    font-weight: normal;

    &.account,
    &.position {
      > span {
        margin-left: 3px;
        text-decoration: underline;

        &.have-ticket {
          cursor: pointer;
          color: #22c759;
        }
      }
    }

    &.level {
      cursor: pointer;
      text-decoration: underline;
      color: #22c759;
    }

    &.schedule {
      justify-content: end;
    }

    &.menu {
      position: relative;
    }
    > .schedule-menu {
      position: absolute;
      width: 120px;
      height: 110px;
      padding: 10px;
      background-color: white;
      border-radius: 5px;
      z-index: 1;
      top: 100%;
      right: 50%;

      > .active {
        color: #22c759;
      }

      &.last-item {
        top: -120%;
        right: 70%;
      }

      > div {
        padding: 5px;
        display: flex;
        cursor: pointer;

        > svg {
          width: 15px;
        }
        > span {
          margin-left: 3px;
          margin-top: 3px;
        }
      }
    }
  }

  > .name {
    color: #333;
    font-size: 14px;
    font-weight: normal;
  }

  > .menu {
    margin-left: auto;
  }
`;

export const ScheduleListBoard = React.memo(
  styled(function ScheduleListBoard({
    className,
    color,
    onDeleteSchedule,
    onStandUpNow,
    schedules = [],
    channels = [],
    onEditSchedule,
  }) {
    const [selectScheduleId, setSelectScheduleId] = useState();

    function showScheduleOptions(id) {
      const scheduleId = id !== selectScheduleId ? id : null;
      setSelectScheduleId(scheduleId);
    }

    return (
      <div className={className}>
        <div className="table-wrapper">
          <div className="table">
            <ScheduleListItemHeader>
              <div className="col account">First working day</div>
              <div className="col position">Second working day</div>
              <div className="col level">Channels</div>
              <div className="col date">Scheduled date</div>
              <div className="col menu" />
            </ScheduleListItemHeader>

            {schedules.map((schedule, index) => (
              <ScheduleListItem
                className="odd-row"
                key={schedule.id}
                schedule={schedule}
                channels={channels}
                color={color}
                lastItem={index + 1 === schedules.length}
                isOpenMenu={selectScheduleId === schedule.id}
                onCloseMenu={() => setSelectScheduleId()}
                setSelectScheduleId={showScheduleOptions}
                onStandUpNow={onStandUpNow}
                onEditSchedule={onEditSchedule}
                onDeleteSchedule={onDeleteSchedule}
              />
            ))}
          </div>
        </div>
      </div>
    );
  })`
    > .title {
      font-size: 20px;
    }

    > .table-wrapper {
      width: 100%;
      overflow: auto;
      border-radius: 6px;
      box-shadow: 0 5px 10px 0 rgba(58, 58, 58, 0.05);
      background-color: #fff;

      > .table {
        min-width: max-content;
      }
    }
  `
);
