import React, { useState } from 'react';
import styled from 'styled-components';
import { theme } from 'styled-tools';
import { Modal } from 'components/Modal';
import { EditableText } from 'features/settings/pages/SettingCompanyPage/EditableText';
import { Button } from 'features/authentication/components/Button';
import { successToast } from 'lib/toast';
import {
  useTicketAction,
  useTicketStandUpSchedule,
} from 'features/projects/useTicketSearch';
import { NoCalendarIcon } from 'components/Icons';
import { LoadingIndicator } from 'components/LoadingIndicator';
import { useQuery } from 'react-query';
import { useAppClient } from 'lib/AppProvider';
import { ScheduleListBoard } from './ScheduleListBoard';
import { StandUpModal } from './StandUpModal';
import { DeleteStandUpModal } from './DeleteStandUpModal';

export const ScheduleModal = React.memo(
  styled(function ScheduleModal({ className, isOpen, onClose }) {
    const [isShowDeleteModal, setIsShowDeleteModal] = useState(false);
    const [selectedTicket, setSelectedTicket] = useState(null);
    const [deletedId, setDeletedId] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const client = useAppClient();
    const { data: channels, isLoading: isLoadingChannel } = useQuery(
      ['slack-channels'],
      () => client.listChannels(),
      {
        enabled: !!isOpen,
        refetchOnWindowFocus: false,
      }
    );

    const { deleteSchedule, updateStandUp } = useTicketAction();

    const { tickets, loading, revalidate } = useTicketStandUpSchedule({
      isOpen,
    });

    function onOpenDeleteModal(id) {
      setDeletedId(id);
      setIsShowDeleteModal(true);
    }

    function onCloseDeleteModal() {
      setDeletedId(null);
      setIsShowDeleteModal(false);
    }

    async function onEditStandUp() {
      setSelectedTicket(null);
      await revalidate();
    }

    async function onUpdateStandUpNow(id) {
      const standUpTicket = tickets.find(ticket => id === ticket.id);
      if (standUpTicket) {
        await updateStandUp(standUpTicket.id, {
          blocker: standUpTicket.arg.blocker,
          previousDate: standUpTicket.arg.previousDate,
          laterDate: standUpTicket.arg.laterDate,
          isScheduled: false,
          scheduleTime: '',
          slackChannelIds: standUpTicket.arg.slackChannelIds,
          previousDayStories: standUpTicket.arg.previousDayStories,
          laterDayStories: standUpTicket.arg.laterDayStories,
        });
        successToast({ message: 'Stand up has been sent to Slack.' });
        await revalidate();
      }
    }

    async function onDeleteStandUp() {
      if (deletedId) {
        setIsSubmitting(true);
        setIsShowDeleteModal(false);
        await deleteSchedule(deletedId);
        successToast({ message: 'The schedule has been deleted.' });
        setIsSubmitting(false);
        setDeletedId(null);
        await revalidate();
      }
    }

    return (
      <>
        {!selectedTicket ? (
          <>
            <Modal
              className="ticket-modal"
              isOpen={isOpen}
              onClose={onClose}
              width="910px"
              padding="30px"
            >
              <div className={className}>
                <div className="form">
                  <div className="header is-mobile">
                    <div className="detail">Schedule list</div>
                  </div>

                  {loading && isLoadingChannel ? (
                    <div className="loading">
                      <LoadingIndicator />
                    </div>
                  ) : tickets.length <= 0 ? (
                    <div className="no-ticket">
                      <NoCalendarIcon size={80} />
                      <div className="empty-title">No schedule</div>
                      <div className="empty-content">
                        There is no schedule for future to stand up your ticket.
                      </div>
                    </div>
                  ) : (
                    <ScheduleListBoard
                      onDeleteSchedule={onOpenDeleteModal}
                      onStandUpNow={onUpdateStandUpNow}
                      schedules={tickets}
                      channels={channels}
                      onEditSchedule={id => {
                        setSelectedTicket(
                          tickets.find(ticket => ticket.id === id)
                        );
                      }}
                    />
                  )}
                </div>
              </div>
            </Modal>
            <DeleteStandUpModal
              isOpen={isShowDeleteModal}
              isSubmitting={isSubmitting}
              onClose={onCloseDeleteModal}
              onConfirm={onDeleteStandUp}
            />
          </>
        ) : (
          <StandUpModal
            isOpen={!!selectedTicket}
            editTicket={selectedTicket}
            onClose={onEditStandUp}
          />
        )}
      </>
    );
  })`
    position: relative;

    .schedule-section {
      display: grid;
      grid-template-columns: 1fr 30px;
      align-items: center;

      p {
        margin-top: 5px;
        color: #9fa3ae;
      }
    }

    .row-date {
      margin-top: 10px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 10px;
    }

    .empty-title {
      font-size: 20px;
      font-weight: bold;
      margin-top: 20px;
      margin-bottom: 10px;
      color: #9fa3ae;
    }

    .no-ticket {
      height: 300px;
      text-align: center;
      padding: 60px 0;
    }
    .empty-content {
      font-size: 14px;
      color: #9fa3ae;
    }

    .is-hidden-desktop {
      display: none;
    }

    .loading {
      background: unset;

      &:after {
        margin: unset;
      }
    }

    @media (max-width: ${theme('breakpoints.mobile')}px) {
      .is-hidden-mobile {
        display: none !important;
      }

      .is-hidden-desktop {
        display: unset !important;
      }

      .repeat-group .row-date {
        margin-bottom: 10px;
      }

      .section.is-hidden-desktop {
        > .actions {
          background: white;
          justify-content: space-between;
          padding: 0 15px;
          padding-top: 30px;

          button {
            width: 100%;
          }
        }

        > .activity-form {
          grid-template-columns: 100%;
          grid-gap: unset;
          margin-top: 10px;
          margin-bottom: 10px;
          padding: 0 20px;
          background: white;

          .form {
            background: white;
          }

          .activity-log {
            align-items: start;

            .right-section .header {
              flex-wrap: wrap;
              line-height: 1.5;
            }

            .see-change {
              font-size: 14px;
            }
          }
        }
      }
    }

    .react-datepicker-popper {
      z-index: 2;
    }

    .section.recurrence {
      width: 280px;
    }

    .form {
      @media (max-width: ${theme('breakpoints.mobile')}px) {
        .action-group {
          display: flex;
          justify-content: space-between;
        }

        > .detail {
          padding: unset !important;
        }
      }

      > .detail > .sidebar {
        > .select-group {
          margin-bottom: 10px;
        }

        > .btn {
          border-color: #c6d5d5;
          width: 100%;

          color: #333;
          font-size: 14px;
          font-weight: bold;

          box-shadow: none;
          background: #f5f8f8;

          margin-bottom: 10px;
        }

        @media (max-width: ${theme('breakpoints.mobile')}px) {
          grid-row: 1;

          > .flag-fields {
            display: none;
          }
        }
      }

      @media (max-width: ${theme('breakpoints.mobile')}px) {
        .is-mobile {
          padding: 15px;
          margin-bottom: 10px !important;
          background: white;
        }

        background: #f5f8f8;
      }

      > .header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        border-bottom: 2px solid #f5f5f5;
        padding-bottom: 20px;

        @media (max-width: ${theme('breakpoints.mobile')}px) {
          flex-direction: column-reverse;
        }
      }

      > .header > .detail {
        margin-right: 20px;
        font-size: 20px;
        font-weight: bold;
      }

      > .header ${EditableText} {
        flex: 1;
        > .text {
          font-size: 20px;

          > .error {
            font-size: 12px;
          }

          &.error {
            color: ${theme('colors.error')};
          }
        }
      }

      .project-name {
        font-size: 12px;

        @media (max-width: ${theme('breakpoints.mobile')}px) {
          font-size: 14px;
        }
      }

      > .header .project-name > span {
        color: ${theme('colors.primary')};
      }

      > .header ${Button} {
        position: relative;
        top: -5px;

        @media (max-width: ${theme('breakpoints.mobile')}px) {
          float: right;
        }
      }

      > .section.member-list {
        margin-top: 16px;

        @media (max-width: ${theme('breakpoints.mobile')}px) {
          margin-top: unset;

          .member-item button div {
            padding-left: 0;
          }
        }

        > .title {
          display: block;

          margin-bottom: 8px;

          color: #c1c3ca;
          font-size: 12px;
          font-weight: normal;
        }
      }

      > .detail {
        > div > .select-group {
          border-radius: 6px;
          padding: 20px;
          background: #f5f8f8;
        }

        > .group {
          width: 100%;

          > .detail-group {
            > .loading {
              height: 300px;
            }
            > .header {
              display: flex;
              gap: 10px;
              align-items: center;
              justify-content: space-between;

              > .date-select {
                display: flex;
                gap: 10px;
                align-items: center;
              }

              > .total-time {
                > span {
                  color: #9fa3ae;
                }
              }
            }
            > .ticket-list {
              margin: 10px 0;
            }
            > h4 {
              margin-bottom: 10px;
            }
          }

          @media (max-width: ${theme('breakpoints.mobile')}px) {
            .repeat-group {
              background: white;
              padding-left: 15px;
              padding-right: 15px;
            }
          }

          > .section {
            margin-bottom: 20px;

            @media (max-width: ${theme('breakpoints.mobile')}px) {
              margin-bottom: unset;
            }

            > .title {
              display: block;

              margin-bottom: 8px;

              color: #c1c3ca;
              font-size: 12px;
              font-weight: normal;
            }

            .field.selected {
              padding-right: 32px;

              > div {
                overflow: hidden;
              }

              @media (max-width: ${theme('breakpoints.mobile')}px) {
                min-height: 40px;
                font-size: 14px;
              }
            }
          }

          .custom-repeat {
            display: flex;
            flex-direction: column;
            row-gap: 20px;
            font-size: 12px;
            color: #c1c3ca;

            > .row {
              display: flex;
              justify-content: space-between;
              column-gap: 12px;
            }

            > .row .week {
              width: 100%;
            }

            span {
              color: #3a3a3a;
            }

            .card-list {
              display: grid;
              grid-template-columns: repeat(3, 1fr);
              grid-gap: 10px;
              margin-top: 16px;
              font-size: 14px;

              .card {
                padding-top: 12px;
                padding-bottom: 12px;
                border-radius: 6px;
                border: solid 1px #dce0ea;
                text-align: center;
                color: #3a3a3a;
                cursor: pointer;
              }

              .card.active {
                border: solid 1px #22c759;
                color: #22c759;
              }
            }

            .column-list {
              display: grid;
              grid-template-columns: 1fr;
              row-gap: 20px;
              margin-top: 16px;

              .date-picker {
                width: 210px;
              }

              .custom-group {
                display: flex;
                align-items: center;
                column-gap: 20px;

                .label {
                  width: 30px;
                }

                .occurrence {
                  width: 210px;
                  margin-bottom: unset;
                }
              }
            }
          }

          > .row-date {
            display: grid;
            grid-template-columns: 280px repeat(2, 1fr);
            grid-gap: 10px;

            .date-type {
              margin-top: 20px;
            }

            > .group {
              position: relative;
              width: 100%;
              display: flex;
              align-items: center;

              .date-picker {
                width: 100%;
                position: absolute;
              }

              > div {
                position: absolute;
                right: 0;
              }

              .icon-close {
                cursor: pointer;
                background-color: ${theme('colors.primary')};
                color: #fff;
                border-radius: 50%;
                height: 20px;
                width: 20px;
                padding: 6px;
                font-size: 12px;
                line-height: 1;
                text-align: center;
                display: table-cell;
                vertical-align: middle;
                margin-top: 18px;
                margin-right: 2rem;

                &:hover {
                  ::after {
                    background: black;
                  }
                }
              }

              @media (max-width: ${theme('breakpoints.tablet')}px) {
                margin-top: 10px;
                margin-bottom: 20px;
              }
            }

            @media (max-width: ${theme('breakpoints.tablet')}px) {
              grid-template-columns: 100%;
              grid-gap: 10px;
            }

            @media (max-width: ${theme('breakpoints.mobile')}px) {
              background: white;
              padding: 15px;
              padding-top: 0;
              grid-template-columns: repeat(2, 1fr) !important;
              grid-gap: 10px !important;

              div:first-child {
                grid-column: span 2;
              }

              > .flag-fields {
                display: block;
              }
            }
          }

          > .row {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 10px;

            @media (max-width: ${theme('breakpoints.mobile')}px) {
              background: white;
              padding: 15px;
              padding-bottom: 0;
              grid-template-columns: repeat(2, 1fr) !important;
              grid-gap: 10px !important;

              div:first-child {
                grid-column: span 2;
              }
            }

            > .flag-fields {
              display: none;
              margin-top: 16px;
            }

            @media (max-width: ${theme('breakpoints.tablet')}px) {
              grid-template-columns: 100%;
              grid-gap: unset;
            }

            @media (max-width: ${theme('breakpoints.mobile')}px) {
              > .flag-fields {
                display: block;
              }
            }
          }
        }
      }

      > .on-drag-enter {
        position: absolute;
        top: -30px;
        left: -30px;
        width: calc(100% + 60px);
        height: calc(100% + 60px);
        border-radius: 6px;
        z-index: -1;
        background: white;
        padding: 30px;

        > .area-drop {
          border: 10px dashed ${theme('colors.primary')};
          height: 100%;
          border-radius: 6px;
          font-size: 36px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: bold;
          color: ${theme('colors.primary')};
        }

        &.dragging {
          z-index: 1;
        }
      }
    }

    .form > .detail,
    > .activity-form {
      display: grid;
      grid-template-columns: minmax(0, 1fr) minmax(0, 250px);
      grid-gap: 30px;
      padding: 20px 0;

      @media (max-width: ${theme('breakpoints.mobile')}px) {
        grid-template-columns: 100%;
        grid-gap: unset;
        margin-top: 10px;
        padding: 0 20px;

        .form {
          background: white;
        }

        .activity-log {
          align-items: start;

          .right-section .header {
            flex-wrap: wrap;
            line-height: 1.5;
          }

          .see-change {
            font-size: 14px;
          }
        }
      }
    }

    .ticket-list {
      margin-bottom: 20px;

      > .ticket {
        margin: 10px 0;
        display: grid;
        grid-template-columns: 30px 1fr 140px;
        align-items: center;

        border-radius: 6px;
        padding: 10px 16px;

        background: #f5f8f8;

        > .title {
          font-size: 14px;
          cursor: pointer;
          width: 350px;

          > .name {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          > .estimation {
            padding-right: 10px;
            white-space: pre;

            color: ${theme('colors.grey')};
            font-size: 14px;
            line-height: 32px;

            svg {
              margin-right: 5px;
            }
          }
        }

        > .status-select {
          margin: 0;

          > .input-group > .field {
            background: #f5f8f8;
            border: none;
          }
        }

        > .assignees {
          justify-content: flex-end;
        }

        > .menu {
          color: #999;
        }
      }

      > .subtask {
        margin-bottom: 10px;

        @media (max-width: ${theme('breakpoints.mobile')}px) {
          grid-template-areas: 'title . menu' 'estimation assignees .';

          grid-template-columns: repeat(2, 1fr) 30px;
          padding: 10px;

          .field-group {
            grid-template-columns: repeat(2, 1fr);

            .text-field:first-child {
              grid-column: span 2;
            }

            .actions-mobile {
              display: flex;
              justify-content: end;
            }

            .member {
              border-left: unset;

              button {
                margin-left: unset;
              }

              .avatar-block {
                margin-right: unset;
                margin-left: -10px;
              }

              .placeholder {
                margin-left: -10px;
              }
            }
          }

          .title {
            grid-area: title;
            grid-column: span 2;

            span:first-child {
              margin-left: unset;
            }
          }

          .estimation {
            grid-area: estimation;

            svg {
              color: #9fa3ae;
            }
          }

          .assignees {
            grid-area: assignees;
            justify-content: flex-start;
            padding-left: 20px;

            .avatar-block {
              margin-right: unset;
              margin-left: -10px;
            }
          }

          .menu {
            grid-area: menu;
          }
        }
      }
    }

    .subtask-btn {
      width: 100%;
      font-size: 14px;
      font-weight: normal;

      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 10px;

      > div {
        background: #22c759;
        border-radius: 50%;
        text-align: center;

        svg {
          color: white;
          width: 12px;
          height: 12px;
          margin: 2px;
        }
      }
    }

    .wrapper-ticket-action {
      display: flex;
      justify-content: end;
      margin-top: 15px;

      .btn-action {
        width: 32px;
        height: 32px;
        margin: 0 0 0 10px;
        padding: 6px;
        border-radius: 6px;
        border: solid 1px #c6d5d5;
        background-color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .actions {
      grid-area: actions;
      display: flex;
      justify-content: flex-end;
      align-self: center;

      .cancel {
        border: solid 1px #c2c3ca;
        color: #c2c3ca;
      }

      .cancel:hover {
        background-color: #c2c3ca;
        color: white;
      }

      > ${Button} {
        &.delete {
          color: #c1c3ca;
          border: none;
        }

        &.delete:hover {
          background: ${theme('colors.error')};
          color: white;
        }

        &:not(:last-child) {
          margin-right: 8px;
        }

        &:disabled {
          background: #adadad;
          cursor: not-allowed;
          border: none;
        }
      }
    }
  `
);
