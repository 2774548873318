import React, { useState, useEffect, useContext } from 'react';
import * as yup from 'yup';
import styled from 'styled-components';
import pick from 'ramda/es/pick';
import { ifProp, theme } from 'styled-tools';
import { Form, Formik } from 'formik';
import {
  Menu,
  MenuButton,
  MenuItem as ReachMenuItem,
  MenuList as ReachMenuList,
} from '@reach/menu-button';
import '@reach/menu-button/styles.css';
import {
  TextField,
  TextFieldView,
} from 'features/authentication/components/TextField';
import { Avatar } from 'components/Avatar';
import { EllipseVerticalIcon, ClockIcon } from 'components/Icons';
import { Indicator } from 'components/SideBarFilter/Indicator';
import { AvatarList } from 'components/AvatarList';
import { Checkbox } from 'components/Checkbox';
import ProjectContext from 'store/projectContext';
import {
  IconButton,
  LinkButton,
} from 'features/authentication/components/Button';

export const MenuList = styled(ReachMenuList)`
  border: 0;
  min-width: 80px;
  border-radius: 6px;
  padding: 6px 0;

  box-shadow: 0 0 10px 0 rgba(51, 51, 51, 0.15);
`;

export const MenuItem = styled(ReachMenuItem).attrs(() => ({
  primary: null,
}))`
  padding: 5px 10px;

  color: ${ifProp('primary', theme('colors.primary'), theme('colors.black'))};

  &:hover {
    color: ${ifProp('primary', theme('colors.primary'), theme('colors.black'))};
    background: #efefef;
  }
`;

const validationSchema = yup.object({
  title: yup.string().required(),
  estimationTimeMinute: yup
    .number()
    .required()
    .min(0, 'Minimum atleast 0')
    .max(59, 'Allowed maximum is 59'),
  estimation: yup.number().required().min(0, 'Minimum atleast 0'),
});

export const SubtaskView = React.memo(
  styled(function SubtaskView({
    className,
    subtask,
    onEdit,
    onDelete,
    onCheck,
  }) {
    return (
      <div className={className}>
        <label className="title">
          <Checkbox
            checked={subtask.isDone}
            onChange={e => onCheck(e.target.checked)}
          />
          <span>{subtask.title}</span>
        </label>
        <div className="estimation">
          <ClockIcon />
          <span>
            {subtask.estimation} hr(s) {subtask.estimationTimeMinute ?? '0'}
            min(s)
          </span>
        </div>
        <AvatarList
          className="assignees"
          data={subtask.assignees}
          compact
          hidePlaceholder
        />
        <Menu>
          <MenuButton as={IconButton} className="menu">
            <EllipseVerticalIcon />
          </MenuButton>
          <MenuList>
            <MenuItem primary onSelect={onEdit}>
              Edit
            </MenuItem>
            <MenuItem onSelect={onDelete}>Delete</MenuItem>
          </MenuList>
        </Menu>
      </div>
    );
  })`
    display: grid;
    grid-template-columns: 1fr 130px 85px 20px;
    align-items: center;

    border-radius: 6px;
    padding: 10px 16px;

    background: #f5f8f8;

    > .title {
      font-size: 14px;
      display: flex;
      align-items: center;
      cursor: pointer;

      > span {
        margin-left: 10px;
        color: #3a3a3a;
        word-break: break-all;
      }
    }

    > .estimation {
      border-right: 1px solid #ddd;
      padding-right: 10px;
      white-space: pre;

      color: ${theme('colors.grey')};
      font-size: 14px;
      line-height: 32px;

      svg {
        margin-right: 5px;
      }
    }

    > .assignees {
      justify-content: flex-end;
    }

    > .menu {
      color: #999;
    }
  `
);

const MemberSelectButton = styled(MenuButton)`
  margin-left: auto;
  width: fit-content;

  border: 0;

  padding: 0;

  background: transparent;
`;

const MemberSelectItem = styled.div`
  display: grid;
  grid-template-columns: 24px 30px 1fr;
  grid-gap: 10px;
  align-items: center;

  padding: 5px 0;

  cursor: pointer;
`;

const MemberSelectBody = styled(MenuList)`
  padding: 20px;
  min-width: 340px;

  > .actions,
  > .field-group,
  > .list {
    margin-bottom: 15px;
  }

  > .list {
    overflow-y: auto;

    max-height: 170px;
  }

  > .empty {
    color: #999;
    text-align: center;
  }

  ${LinkButton} {
    font-size: 12px;
    font-weight: bold;

    &.unselected {
      margin-left: 20px;
    }
  }
`;

export const MemberSelect = styled(function MemberSelect({
  projectId,
  className,
  value,
  onChange,
  compact,
}) {
  const [mounted, setMounted] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [projectMembers, setProjectMembers] = useState([]);
  const [filteredMembers, setFilteredMembers] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const { projectState } = useContext(ProjectContext);
  const { members } = projectState;

  useEffect(() => {
    if (!mounted && members && members.length > 0) {
      const memberList = members?.map(item => ({
        ...item,
        isSelected: value?.some(v => v.id === item.id),
      }));
      setProjectMembers(memberList);
      setFilteredMembers(memberList);
      setMounted(true);
    }
  }, [members, mounted, value]);

  useEffect(() => {
    if (searchQuery) {
      const memberList = projectMembers.filter(
        item =>
          item?.name?.toLowerCase()?.includes(searchQuery.toLowerCase()) ||
          item?.nickName?.toLowerCase()?.includes(searchQuery.toLowerCase())
      );
      setFilteredMembers(memberList);
    } else if (projectMembers.length > 0) {
      setFilteredMembers(projectMembers);
    }
  }, [searchQuery, projectMembers]);

  useEffect(() => {
    if (value.length > 0 && projectMembers.length > 0) {
      const filtered = projectMembers.filter(item =>
        value?.some(v => v.id === item.id)
      );
      setSelectedMembers(filtered);
    } else if (mounted) {
      setSelectedMembers([]);
    }
  }, [value, projectMembers, mounted]);

  return (
    <Menu>
      <MemberSelectButton>
        <AvatarList data={value} compact={compact} />
      </MemberSelectButton>
      <MemberSelectBody className={className}>
        <div className="actions">
          <LinkButton
            primary
            type="button"
            onClick={() => onChange(filteredMembers)}
          >
            Select all
          </LinkButton>
          <LinkButton
            className="unselected"
            type="button"
            onClick={() => onChange([])}
          >
            Unselect all
          </LinkButton>
        </div>
        <div className="field-group">
          <TextFieldView
            placeholder="Search member"
            value={searchQuery}
            onChange={e => setSearchQuery(e.target.value)}
          />
        </div>
        <div className="list">
          {filteredMembers.map(item => {
            return (
              <MemberSelectItem
                tabIndex={-1}
                key={item.id}
                onClick={() =>
                  selectedMembers?.some(selected => selected.id === item.id)
                    ? onChange(value.filter(v => item.id !== v.id))
                    : onChange(value.concat(item))
                }
              >
                <Indicator
                  checked={selectedMembers?.some(
                    selected => selected.id === item.id
                  )}
                />
                <Avatar {...pick(['avatarUrl', 'name', 'initial'], item)} />
                <div className="name">{item.name}</div>
              </MemberSelectItem>
            );
          })}
        </div>
        {selectedMembers.length === 0 ? (
          <div className="empty">Select member to add to ticket...</div>
        ) : (
          <AvatarList
            data={selectedMembers}
            hidePlaceholder
            onSelect={id => onChange(value.filter(v => v.id !== id))}
          />
        )}
      </MemberSelectBody>
    </Menu>
  );
})``;

export const SubtaskEditor = React.memo(
  styled(function SubtaskEditor({
    className,
    subtask = {},
    onCancel,
    onSubmit,
    projectId,
  }) {
    const initialValues = {
      id: subtask.id ?? null,
      title: subtask.title ?? '',
      estimation: subtask.estimation ?? 0,
      estimationTimeMinute: subtask.estimationTimeMinute ?? 0,
      assignees: subtask.assignees ?? [],
      isDone: subtask.isDone ?? false,
    };

    return (
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, setFieldValue }) => (
          <Form className={className} noValidate>
            <div className="field-group">
              <TextField
                className="text-field"
                name="title"
                label="Write your task"
                oldLabel
              />
              <TextField
                className="text-field"
                name="estimation"
                label="Hours"
                min="0"
                type="number"
                oldLabel
              />
              <TextField
                className="text-field"
                name="estimationTimeMinute"
                min="0"
                max="59"
                label="Minutes"
                type="number"
                oldLabel
              />
              <div className="member">
                <MemberSelect
                  projectId={projectId}
                  compact
                  value={values.assignees}
                  onChange={val => setFieldValue('assignees', val)}
                />
              </div>
              <div className="actions-mobile is-hidden-desktop">
                <LinkButton type="button" onClick={onCancel}>
                  Cancel
                </LinkButton>
                <LinkButton className="save" type="submit" primary>
                  Save
                </LinkButton>
              </div>
            </div>
            <div className="actions is-hidden-mobile">
              <LinkButton type="button" onClick={onCancel}>
                Cancel
              </LinkButton>
              <LinkButton className="save" type="submit" primary>
                Save
              </LinkButton>
            </div>
          </Form>
        )}
      </Formik>
    );
  })`
    border-radius: 6px;
    padding: 10px 16px;

    background: #f5f8f8;

    > .field-group {
      display: grid;
      grid-template-columns: 1fr 60px 75px 105px;
      grid-gap: 10px;

      margin-bottom: 10px;

      > .member {
        display: flex;
        border-left: 1px solid #ddd;
        align-self: end;
        height: 38px;
      }

      label {
        background: unset;
        color: #9fa3ae;
      }
    }

    .text-field {
      margin: 0;
    }

    .text-field input {
      box-shadow: none;
    }

    > .actions,
    .actions-mobile {
      text-align: right;

      ${LinkButton} {
        font-size: 12px;

        &.save {
          margin-left: 20px;

          font-weight: bold;
        }
      }
    }
  `
);

export const Subtask = styled(function Subtask({
  className,
  onSubmit,
  subtask,
  ...props
}) {
  const [isEditing, setIsEditing] = useState(false);

  if (isEditing) {
    return (
      <SubtaskEditor
        {...props}
        className={className}
        subtask={subtask}
        onCancel={() => setIsEditing(false)}
        onSubmit={value => {
          onSubmit(value);
          setIsEditing(false);
        }}
      />
    );
  }

  return (
    <SubtaskView
      {...props}
      className={className}
      subtask={subtask}
      onEdit={() => setIsEditing(true)}
      onCheck={checked => onSubmit({ ...subtask, isDone: checked })}
    />
  );
})``;
